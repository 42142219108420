<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountProfile.title")}}</h2>
        <div>{{$t("AccountPage.AccountProfile.description")}}</div>
        
        <div v-if="userDetail.tariff && userDetail.tariff.code" class="alert alert-info alert-dismissible mb-2" role="alert">
            <h4 class="alert-heading mb-2">{{ $t("cabinet.tariff") }}: {{ userDetail.tariff.nameRu }} </h4>
            <p>{{ $t("cabinet.period") }}: {{ userDetail.tariffBeginDate }} - {{ userDetail.tariffEndDate }}</p>
        </div>

        <div v-if="$route.query.invalid == 1" class="alert alert-info">
            {{ $t("AccountPage.AccountProfile.fill_profile_for_tariff") }}
        </div>
        
        <div v-if="userDetail.tariff && userDetail.tariff.code === 'DEMO'" class="alert alert-info">
            {{ $t("AccountPage.AccountProfile.free_tender") }}
        </div>

        <beat-loader 
            v-if="loading" 
            class="loader-center" 
            :color="'#553DB4'" 
            :loading="loading" 
            :size="20" 
            :sizeUnit="'px'"
            />
        <form @submit.prevent="onSubmit()" autocomplete="off">
            <div class="form-body">
                <h4 class="form-section mt-4">{{$t("organization.contact_info")}}</h4>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="surnameInput">{{$t("organization.surname")}} </label>
                            <span v-show="$v.userDetail.surname.$invalid"> * </span>
                            <input class="form-control" type="text" v-model="userDetail.surname" :placeholder="$t('organization.surname')" id="surnameInput" name="surname">
                            <template v-if="$v.userDetail.surname.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.surname.required">{{ $t('CommonForm.field_is_required') }}</small>
                            <small class="form-text text-danger" v-if="!$v.userDetail.surname.min">{{ $t('CommonForm.at_least_3_characters') }}</small>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="firstnameInput">{{$t("organization.name")}} </label>
                            <span v-show="$v.userDetail.firstname.$invalid"> * </span>
                            <input class="form-control" type="text" v-model="userDetail.firstname" :placeholder="$t('organization.name')" id="firstnameInput" name="firstname">
                            <template v-if="$v.userDetail.firstname.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.firstname.required">{{ $t('CommonForm.field_is_required') }}</small>
                            <small class="form-text text-danger" v-if="!$v.userDetail.firstname.min">{{ $t('CommonForm.at_least_3_characters') }}</small>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="patronnameInput">{{$t("organization.patronname")}} </label>
                            <input class="form-control" type="text" v-model="userDetail.patronname" :placeholder="$t('organization.patronname')" id="patronnameInput" name="patronname">
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6" v-if="userDetail.ownershipType != 3">
                        <div class="form-group">
                            <label for="iinInput">{{$t("organization.iin")}}</label>
                            <span v-show="$v.userDetail.iin.$invalid"> * </span>
                            <input class="form-control" type="text" minlength="12" maxlength="12" v-model="userDetail.iin" id="iinInput" name="iin">
                            <template v-if="$v.userDetail.iin.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.iin.required">{{ $t('CommonForm.field_is_required') }}</small>
                            <small class="form-text text-danger" v-if="!$v.userDetail.iin.number">{{ $t('CommonForm.enter_12_digit_number') }}</small>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="birthDateInput">{{$t("organization.birth_date")}}</label>
                            <!-- <input class="form-control dt-pick" type="text" v-model="userDetail.birthDate" :placeholder="$t('organization.birth_date')" id="birthDateInput" name="birthDate"> -->
                            <datepicker :bootstrap-styling="true" :value="startDate(userDetail.birthDate)" format="dd.MM.yyyy" @selected="birthdateSelected" :language="datepickerLanguage"></datepicker>
                        </div>     
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="orgPhoneNumberInput">{{$t("organization.orgPhoneNumber")}}</label>
                            <span v-show="$v.userDetail.orgPhoneNumber.$invalid"> * </span>
                            <input class="form-control" type="tel" v-model="userDetail.orgPhoneNumber" maxlength="20" :placeholder="$t('organization.orgPhoneNumber')" id="orgPhoneNumberInput" name="orgPhoneNumber">
                            <template v-if="$v.userDetail.orgPhoneNumber.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.orgPhoneNumber.required">{{ $t('CommonForm.field_is_required') }}</small>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="addressInput">{{$t("organization.address")}}</label>
                            <span v-show="$v.userDetail.address.$invalid"> * </span>
                            <input class="form-control" type="text" v-model="userDetail.address" :placeholder="$t('organization.address')" id="addressInput" name="address">
                            <template v-if="$v.userDetail.address.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.address.required">{{ $t('CommonForm.field_is_required') }}</small>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="telegramIdInput">Telegram ID <i class="fab fa-telegram" style="color:#4FC1EC"></i></label>
                            <input class="form-control" type="text" maxlength="50" v-model="userDetail.telegramId" id="telegramIdInput" name="telegramId">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="orgWebSiteInput">{{$t("organization.website")}}</label>
                            <input class="form-control" type="text" v-model="userDetail.orgWebsite" placeholder="http://" id="orgWebSiteInput" name="orgWebSite">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="emailInput">Email</label>
                            <input class="form-control" type="text" v-model="userDetail.email" id="emailInput" name="email" disabled>
                        </div>
                    </div>
                </div>

                <h4 class="form-section mt-4" v-if="userDetail.ownershipType == 2 || userDetail.ownershipType == 3">{{$t("organization.org_info")}}</h4>
                <div class="form-row" v-if="userDetail.ownershipType == 2 || userDetail.ownershipType == 3">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="orgCertificateDateInput">{{$t("organization.orgCertificateDate")}}</label>
                            <!-- <input class="form-control dt-pick" type="text" v-model="userDetail.orgCertificateDate" placeholder="ДД.ММ.ГГГГ" id="orgCertificateDateInput" name="orgCertificateDate"> -->
                            <datepicker :bootstrap-styling="true" :value="startDate(userDetail.orgCertificateDate)" format="dd.MM.yyyy" @selected="orgCertificateDateSelected" :language="datepickerLanguage"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="orgRegistrationDateInput">{{$t("organization.orgRegistrationDate")}}</label>
                            <!-- <input class="form-control dt-pick" type="text" v-model="userDetail.orgRegistrationDate" placeholder="ДД.ММ.ГГГГ" id="orgRegistrationDateInput" name="orgRegistrationDate"> -->
                            <datepicker :bootstrap-styling="true" :value="startDate(userDetail.orgRegistrationDate)" format="dd.MM.yyyy" @selected="orgRegistrationDateSelected" :language="datepickerLanguage"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="userDetail.ownershipType == 2">
                        <div class="form-group">
                            <label for="orgCertificateSeriesInput">{{$t("organization.orgCertificateSeries")}}</label>
                            <input class="form-control" type="text" v-model="userDetail.orgCertificateSeries" :placeholder="$t('organization.orgCertificateSeries')" id="orgCertificateSeriesInput" name="orgCertificateSeries">
                        </div>
                    </div>
                    <div class="col-md-4" v-if="userDetail.ownershipType == 3">
                        <div class="form-group">
                            <label for="binInput">{{$t("organization.biniin")}}</label>
                            <span v-show="$v.userDetail.iin.$invalid"> * </span>
                            <input class="form-control" type="text" maxlength="12" v-model="userDetail.iin" id="binInput" name="iin">
                            <template v-if="$v.userDetail.iin.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.iin.required">{{ $t('CommonForm.field_is_required') }}</small>
                            <small class="form-text text-danger" v-if="!$v.userDetail.iin.number">{{ $t('CommonForm.enter_12_digit_number') }}</small>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="form-row" v-if="userDetail.ownershipType == 3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="orgFullNameRuInput">{{$t("organization.orgFullNameRu")}}</label>
                            <span v-show="$v.userDetail.orgFullNameRu.$invalid"> * </span>
                            <div class="input-group">
                                <div class="input-group-prepand">
                                    <select class="form-control" v-model="userDetail.legalEntityType">
                                        <option v-for="item in [{id:0, label:''},{id:1, label:'АО'},{id:2, label:'ТОО'}]" v-bind:key="item.id" v-bind:value="item.id">{{item.label}}</option>
                                    </select>
                                </div>
                                <input class="form-control" type="text" v-model="userDetail.orgFullNameRu" :placeholder="$t('organization.orgFullNameRu')" id="orgFullNameRuInput" name="orgFullNameRu">
                            </div>
                            <template v-if="$v.userDetail.orgFullNameRu.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.orgFullNameRu.required">{{ $t('CommonForm.field_is_required') }}</small>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="orgFullNameKzInput">{{$t("organization.orgFullNameKz")}}</label>
                            <input class="form-control" type="text" v-model="userDetail.orgFullNameKz" :placeholder="$t('organization.orgFullNameKz')" id="orgFullNameKzInput" name="orgFullNameKz">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="legalAddressInput">{{$t("organization.legal_address")}}</label>
                            <span v-show="$v.userDetail.legalAddress.$invalid"> * </span>
                            <input class="form-control" type="text" v-model="userDetail.legalAddress" :placeholder="$t('organization.legal_address')" id="legalAddressInput" name="legalAddress">
                            <template v-if="$v.userDetail.legalAddress.$error">
                            <small class="form-text text-danger" v-if="!$v.userDetail.legalAddress.required">{{ $t('CommonForm.field_is_required') }}</small>
                            </template>
                        </div>
                    </div>
                </div>
            </div>                
            <div class="form-row">
                <div class="col-12">
                <button type="submit" class="btn btn-lg btn-zhako float-right">
                    <i class="icon-check2"></i> {{$t("organization.save")}}
                </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    // import { validationMixin } from 'vuelidate'
    import { required, requiredIf, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";
    import datepickerMixin from '@/mixins/datepicker.js'
import moment from "moment"


    export default {
        name: 'user-profile',
        mixins: [
            // validationMixin
            datepickerMixin
        ],
        data() {
            return {
                loading: false,
                isSubmitted: true,
                userDetail: {
                }
            }
        },
        validations: {
            userDetail: {
                firstname: { required, min: minLength(3) },
                surname: { required, min: minLength(3) },
                email: { email },
                iin: { required, numeric, min: minLength(12), max: maxLength(12) },
                orgPhoneNumber: { required },
                address: { required },
                orgFullNameRu: { required: requiredIf(function(m) { return m.ownershipType == 3 }) },
                legalAddress: { required: requiredIf((m) => m.ownershipType == 3) },
            }
        },
        created() {
            this.onCreate();
        },
        mounted() {
            this.onShow();
        },
        methods: {
            onCreate() {
                this.$store.dispatch("LOAD_PROFILE")
                .then(result => this.userDetail = result);
            },
            onShow() {

            },
            onSubmit() {
                // this.isSubmitted = true;
                this.$v.userDetail.$touch();
                if (! this.$v.userDetail.$invalid) {
                    this.$store.dispatch("SAVE_PROFILE", this.userDetail)
                    .then(result => result.id && this.$toast.success( this.$t("AccountPage.AccountProfile.profile_data_saved") ));
                }
                else {
                    this.$toast.error( this.$t("AccountPage.AccountProfile.fill_required_information") );
                }
            },
            birthdateSelected(date) {
                if (date) {
                    this.userDetail.birthDate = moment(Date.parse(date)).format('DD.MM.YYYY');
                }
            },
            orgCertificateDateSelected(date) {
                if (date) {
                    this.userDetail.orgCertificateDate = moment(Date.parse(date)).format('DD.MM.YYYY');
                }
            },
            orgRegistrationDateSelected(date) {
                if (date) {
                    this.userDetail.orgRegistrationDate = moment(Date.parse(date)).format('DD.MM.YYYY');
                }
            }
        },
        computed: {
            ...mapGetters([
                'PROFILE'
            ]),
            profile() {
                return this.PROFILE;
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>