import Datepicker from "vuejs-datepicker"
import { kk, ru } from "vuejs-datepicker/dist/locale"
import moment from "moment"

export default {
    components: {
        Datepicker
    },
    data() {
        return {
            kk: kk,
            ru: ru
        };
    },
    computed: {
        datepickerLanguage() {
            if (this.$i18n.locale === 'kz') {
                return this.kk;
            }
            return this.ru;
        }
    },
    methods: {
        datepickerFormat(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        startDate(date) {
            return moment(date, 'DD.MM.YYYY').format();
        },
    }
}